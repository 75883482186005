import React from "react";
import Text from "./Text/Text";
import Select from "./Select/Select";
import Date from "./Date/Date";

const Input = (props) => {
    const { type, placeholder, minYears, maxYears, list, onChange, error, errorMessage } = props;
    switch (type) {
        case "text":
        case "number":
        case "email":
            return (
                <Text
                    placeholder={placeholder}
                    type={type}
                    onChange={onChange}
                    error={error}
                    errorMessage={errorMessage}
                />
            );
        case "select":
            return (
                <Select
                    placeholder={placeholder}
                    list={list}
                    onChange={onChange}
                    error={error}
                    errorMessage={errorMessage}
                />
            );
        case "date":
            return (
                <Date
                    placeholder={placeholder}
                    minYears={minYears}
                    maxYears={maxYears}
                    onChange={onChange}
                    error={error}
                    errorMessage={errorMessage}
                />
            );
        default:
            return "";
    }
};

export default Input;
