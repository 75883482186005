import React from "react";
// import { useRouter } from "next/router";
import Banner from "../../component/Banner/Banner";
import Article from "../../component/Article/Article";
import image_1 from "../../images/about.jpg";
import image_2 from "../../images/how.jpg";
import css from "./Homepage.module.css";
import en from "./en.json";

const Homepage = () => {
    // const { locale } = useRouter();
    // const lang = {en}

    return (
        <>
            <Banner />
            <Article title={en.about.title} text={en.about.text} image={image_1} />
            <div className={css.border} />
            <Article title={en.how.title} text={en.how.text} list={en.how.list} image={image_2} />
        </>
    );
};

export default Homepage;
