export const validate = form => {
    const newForm = [...form];
    let error = false;
    newForm.map((item, index) => {
        item.form.map((itemForm, indexForm) => {
            if (itemForm.value === '' || (itemForm.type === 'date' && itemForm.value === 'Invalid Date')) {
                itemForm.error = true;
                itemForm.errorMessage = 'Field cannot be empty.';
                error = true;
            } else {
                if(itemForm.type === 'email' && !validateEmail(itemForm.value)) {
                    itemForm.error = true;
                    itemForm.errorMessage = 'Email format is incorrect.';
                    error = true;
                }
                else {
                    itemForm.error = false;
                    itemForm.errorMessage = '';
                }
            }
        })
    })
    return [newForm, error];
}

const validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}