import * as React from "react";
import Checkbox from "@mui/joy/Checkbox";
import css from "./BasicCheckbox.module.css";

export default function BasicCheckbox(props) {
    const { label, onChange } = props;

    const handleChange = (e) => {
        onChange(e.target.checked);
    };

    return (
        <div className={css.container}>
            <Checkbox
                onChange={handleChange}
                sx={{
                    backgroundColor: "#000",
                    transform: "scale(1.2)",
                    "& .MuiCheckbox-checkbox": {
                        borderRadius: 0,
                    },
                }}
                slotProps={{
                    action: ({ checked }) => ({
                        sx: {
                            bgcolor: checked ? "background.level1" : "transparent",
                            boxShadow: checked ? "sm" : "none",
                        },
                    }),
                }}
            />
            <span className={css.label} dangerouslySetInnerHTML={{ __html: label }} />
        </div>
    );
}
