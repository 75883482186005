import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const BasicDatePicker = (props) => {
    const { placeholder, onChange, minYears, maxYears, error, errorMessage } = props;
    const [value, setValue] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);

    const handleChange = (newValue) => {
        setValue(newValue);
        onChange(dayjs(newValue).format("YYYY-MM-DD"));
    };

    function subtractYears(date, years) {
        date.setFullYear(date.getFullYear() - years);
        return date;
    }

    useEffect(() => {
        const date = new Date();
        if (minYears !== undefined && minYears !== null) {
            const newDate = subtractYears(date, minYears);
            setMinDate(newDate);
        }
    }, [minYears]);

    useEffect(() => {
        const date = new Date();
        if (maxYears !== undefined && maxYears !== null) {
            const newDate = subtractYears(date, maxYears);
            setMaxDate(newDate);
        }
    }, [maxYears]);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    value={value}
                    label={placeholder}
                    onChange={handleChange}
                    maxDate={dayjs(minDate)}
                    minDate={dayjs(maxDate)}
                    renderInput={(params) => (
                        <TextField
                            sx={{
                                "&.MuiTextField-root": {
                                    backgroundColor: error ? "var(--red)" : "var(--inputBkg)",
                                    width: "100%",

                                    "& .MuiInputLabel-root": {
                                        color: "var(--text)",
                                        fontSize: "14px",
                                        height: "55px",
                                        display: "flex",
                                        alignItems: "center",
                                        "@media(max-width: 991px)": {
                                            fontSize: "14px",
                                            padding: "14px 25px",
                                        },
                                        letterSpacing: 0,
                                        fontFamily: "var(--font-replica)",
                                        padding: "10px 25px",
                                        textTransform: "uppercase",
                                        transform: "none",
                                    },
                                    "& input": {
                                        color: "var(--text)",
                                        height: "34px",
                                        fontSize: "14px",
                                        letterSpacing: 0,
                                        fontFamily: "var(--font-replica)",
                                        padding: "10px 25px 9px",
                                        "&::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder": {
                                            opacity: 1,
                                        },
                                    },
                                    "& fieldset": {
                                        borderColor: "#FFF",
                                        borderRadius: 0,
                                        top: 0,
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#FFF !important",
                                        borderWidth: "1px !important",
                                    },
                                    "& .MuiInputLabel-shrink": {
                                        display: "none",
                                    },
                                    "& svg": {
                                        color: "#FFF",
                                    },
                                    "& legend": {
                                        display: "none",
                                    },
                                },
                            }}
                            {...params}
                        />
                    )}
                />
            </LocalizationProvider>
            <div className="errorMessage">{errorMessage}</div>
        </>
    );
};

export default BasicDatePicker;
