const fullname = "Full name (as per passport/id)";
const dob = "Date of birth (as per passport/id)";

const countries = [
    {
        title: "Abu Dhabi",
        value: "Abu Dhabi",
    },
    {
        title: "Dubai",
        value: "Dubai",
    },
    {
        title: "Sharjah",
        value: "Sharjah",
    },
    {
        title: "Ajman",
        value: "Ajman",
    },
    {
        title: "Umm Al Quwain",
        value: "Umm Al Quwain",
    },
    {
        title: "Ras Al Khaimah",
        value: "Ras Al Khaimah",
    },
    {
        title: "Fujairah",
        value: "Fujairah",
    },
];

export const form_en = [
    {
        title: "",
        form: [
            {
                name: "team_name",
                placeholder: "Team name",
                value: "",
                type: "text",
            },
        ],
    },
    {
        title: "",
        form: [
            {
                name: "category",
                placeholder: "Category",
                value: "",
                type: "select",
                list: [
                    {
                        title: "males team",
                        value: "males",
                    },
                    {
                        title: "females team",
                        value: "females",
                    },
                ],
            },
        ],
    },
    {
        title: "player 1",
        form: [
            {
                name: "player1_fullname",
                placeholder: fullname,
                value: "",
                type: "text",
            },
            {
                name: "player1_dob",
                placeholder: dob,
                value: "",
                type: "date",
                minYears: 14,
                maxYears: 16,
            },
            {
                name: "player1_emirate",
                placeholder: "Emirate",
                value: "",
                type: "select",
                list: countries,
            },
        ],
    },
    {
        title: "player 2",
        form: [
            {
                name: "player2_fullname",
                placeholder: fullname,
                value: "",
                type: "text",
            },
            {
                name: "player2_dob",
                placeholder: dob,
                value: "",
                type: "date",
                minYears: 14,
                maxYears: 16,
            },
            {
                name: "player2_emirate",
                placeholder: "Emirate",
                value: "",
                type: "select",
                list: countries,
            },
        ],
    },
    {
        title: "player 3",
        form: [
            {
                name: "player3_fullname",
                placeholder: fullname,
                value: "",
                type: "text",
            },
            {
                name: "player3_dob",
                placeholder: "Date of birth (as per passport/id)",
                value: "",
                type: "date",
                minYears: 14,
                maxYears: 16,
            },
            {
                name: "player3_emirate",
                placeholder: "Emirate",
                value: "",
                type: "select",
                list: countries,
            },
        ],
    },
    {
        title: "player 4",
        form: [
            {
                name: "player4_fullname",
                placeholder: fullname,
                value: "",
                type: "text",
            },
            {
                name: "player4_dob",
                placeholder: dob,
                value: "",
                type: "date",
                minYears: 14,
                maxYears: 16,
            },
            {
                name: "player4_emirate",
                placeholder: "Emirate",
                value: "",
                type: "select",
                list: countries,
            },
        ],
    },
    {
        title: "player 5",
        form: [
            {
                name: "player5_fullname",
                placeholder: fullname,
                value: "",
                type: "text",
            },
            {
                name: "player5_dob",
                placeholder: dob,
                value: "",
                type: "date",
                minYears: 14,
                maxYears: 16,
            },
            {
                name: "player5_emirate",
                placeholder: "Emirate",
                value: "",
                type: "select",
                list: countries,
            },
        ],
    },
    {
        title: "goalie",
        form: [
            {
                name: "goalie_fullname",
                placeholder: fullname,
                value: "",
                type: "text",
            },
            {
                name: "goalie_dob",
                placeholder: dob,
                value: "",
                type: "date",
                minYears: 14,
                maxYears: 16,
            },
            {
                name: "goalie_emirate",
                placeholder: "emirate",
                value: "",
                type: "select",
                list: countries,
            },
        ],
    },
    {
        title: "coach",
        text: "(Must be +21 years old will be point of contact for the team)",
        large: true,
        form: [],
    },
    {
        title: "",
        form: [
            {
                name: "coach_fullname",
                placeholder: fullname,
                value: "",
                type: "text",
            },
            {
                name: "coach_dob",
                placeholder: dob,
                value: "",
                type: "date",
                minYears: 21,
                maxYears: 70,
            },
            {
                name: "coach_emirate",
                placeholder: "emirate",
                value: "",
                type: "select",
                list: countries,
            },
        ],
    },
    {
        title: "",
        form: [
            {
                name: "coach_email",
                placeholder: "Email address",
                value: "",
                type: "email",
            },
            {
                name: "coach_mobile",
                placeholder: "Mobile number",
                value: "",
                type: "number",
            },
            {
                name: "coach_id",
                placeholder: "emirate ID number",
                value: "",
                type: "number",
            },
        ],
    },
];
