import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import css from "./Message.module.css";
import bkg from "../../images/grey-texture.jpg";
import logo from "../../images/5v5-logo.svg";
import cameo from "../../images/camo-white-verticle.svg";

const Message = ({ text }) => {
    return (
        <div className={css.container} style={{ backgroundImage: `url(${bkg})` }}>
            <img className={css.imgCameo} alt="" src={cameo} />

            <div className={css.body}>
                <Header />
                <div className={css.logo}>
                    <Link to="/">
                        <img alt="" src={logo} />
                    </Link>
                </div>
                <div className={css.textContainer}>
                    <div className={css.text} dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>
        </div>
    );
};

export default Message;
