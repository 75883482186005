import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

const BasicSelect = (props) => {
    const { placeholder, list, onChange, error, errorMessage } = props;
    const [value, setValue] = useState("");
    const [status, setStatus] = useState(true);

    const handleChange = (event) => {
        setValue(event.target.value);
        onChange(event.target.value);
    };

    return (
        <>
            <FormControl fullWidth>
                <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={value}
                    displayEmpty
                    onChange={handleChange}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                background: "transparent",
                            },
                        },
                    }}
                    sx={{
                        backgroundColor: error ? "var(--red)" : "var(--inputBkg)",
                        borderRadius: 0,
                        color: "var(--text)",
                        textAlign: "left",
                        fontSize: "14px",
                        height: "55px",
                        "@media(max-width: 991px)": {
                            fontSize: "14px",
                        },
                        padding: 0,
                        fontFamily: "var(--font-replica)",
                        "& .MuiSelect-select": {
                            padding: "10px 25px  9px",
                            textTransform: "uppercase",
                            letterSpacing: 0,
                            "@media(max-width: 991px)": {
                                padding: "15px 25px  14px",
                            },
                        },
                        "& fieldset": {
                            borderColor: "#FFF",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "inherit !important",
                            borderWidth: "1px !important",
                        },
                        "& svg": {
                            color: "#FFF",
                        },
                    }}
                >
                    <MenuItem
                        // disabled
                        value=""
                        sx={{
                            paddingTop: "10px",
                            textTransform: "uppercase",
                            color: "var(--text)",
                            fontFamily: "var(--font-replica)",
                            letterSpacing: 0,
                            fontSize: "14px",
                            "@media(max-width: 991px)": {
                                fontSize: "14px",
                            },
                            backgroundColor: "var(--inputBkg) !important",
                            "&.Mui-disabled": {
                                opacity: 1,
                            },
                            "&.Mui-selected, &.Mui-selected:hover, &:hover": {
                                backgroundColor: "var(--inputBkg) !important",
                            },
                        }}
                    >
                        {placeholder}
                    </MenuItem>
                    {list.map((item, index) => (
                        <MenuItem
                            key={index}
                            value={item.value}
                            sx={{
                                textTransform: "uppercase",
                                color: "var(--text)",
                                fontFamily: "var(--font-replica)",
                                fontSize: "14px",
                                "@media(max-width: 991px)": {
                                    fontSize: "14px",
                                },
                                letterSpacing: 0,
                                backgroundColor: "var(--inputBkg)",
                                outline: "none",
                                "&.Mui-selected, &.Mui-selected:hover, &:hover": {
                                    backgroundColor: "var(--inputBkg)",
                                    color: "var(--text)",
                                },
                            }}
                        >
                            {item.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="errorMessage">{errorMessage}</div>
        </>
    );
};

export default BasicSelect;
