import Input from "@mui/joy/Input";

const Text = (props) => {
    const { type, placeholder, onChange, error, errorMessage } = props;
    return (
        <>
            <Input
                type={type}
                variant="outlined"
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                sx={{
                    borderColor: "#FFF",
                    backgroundColor: error ? "var(--red)" : "var(--inputBkg)",
                    borderRadius: 0,
                    color: "var(--text)",
                    fontSize: "14px",
                    height: "53px",
                    "& input": {
                        textTransform: "uppercase",
                    },
                    "@media(max-width: 991px)": {
                        fontSize: "14px",
                    },
                    letterSpacing: 0,
                    fontFamily: "var(--font-replica)",
                    "--Input-minHeight": "53px",
                    "--Input-placeholderOpacity": 1,
                    "--Input-focusedHighlight": "#FFFFFF",
                    "--Input-focusedThickness": "1px",
                    "&.MuiInput-root:hover": {
                        color: "var(--text)",
                        borderColor: "var(--text)",
                    },
                }}
            />
            <div className="errorMessage">{errorMessage}</div>
        </>
    );
};

export default Text;
