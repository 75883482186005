import React from "react";
import { Link } from "react-router-dom";
import css from "./Banner.module.css";
import imgKid from "../../images/banner-1.png";
import imgKidMobile from "../../images/banner-m.jpg";
import imgFrame from "../../images/frame.svg";

const Banner = () => {
    return (
        <div className={css.container}>
            <div className={css.imgLgLeft}>
                <img alt="" className={css.desktop} src={imgKid} />
                <img alt="" className={css.mobile} src={imgKidMobile} />
            </div>
            <div className={css.group}>
                <img className={css.frameImg} alt="" src={imgFrame} />

                <div className={css.button}>
                    <Link to="/register">register my team</Link>
                </div>
            </div>
        </div>
    );
};

export default Banner;
