import css from './Article.module.css';

const Article = props => {
    const {title, text, image, list} = props;
    return(
        <div className={css.container}>
            <div className={css.paragraph}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className={css.title}>{title}</div>
                            <div className={css.text} dangerouslySetInnerHTML={{__html: text}}/>
                            {list !== undefined && list.length > 0 ?
                                <p>
                                    <ul className={css.list}>
                                        {list.map((item, index) =>
                                            <li key={index}>
                                                <span className={css.indexing}>{index + 1}.</span><span dangerouslySetInnerHTML={{__html: item}}></span>
                                            </li>
                                        )}
                                    </ul>
                                </p>
                            : ''
                            }
                        </div>
                        <div className="col-12 col-lg-6">
                            <img alt={title} className={css.image} src={image}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Article;