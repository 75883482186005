import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/fonts.css";
import "./styles/globals.css";
import "./styles/bootstrap.css";
import Homepage from "./container/Homepage/Homepage";
import Register from "./container/Register/Register";
import Response from "./container/Response/Response";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/register" element={<Register />} />
                <Route path="/success" element={<Response />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
