import React, { useState, useEffect } from "react";
import Input from "../../../component/Input/Input";
import BasicCheckbox from "../../../component/Input/BasicCheckbox/BasicCheckbox";
import css from "./Form.module.css";
import en from "./en.json";
import { form_en } from "./form_en.js";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { validate } from "../../../utils/validate";
// import tcPdf from "../../../pdf/GTRD_5v5_T&C_UAE_2023 Final.pdf";
import tcPdf from "../../../pdf/GTRD_5v5_TC_UAE_2024 final.pdf";
import policyPdf from "../../../pdf/Privacy Policy (EN).pdf";

const Form = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    // const lang = { en };
    const form_lang = { form_en };
    const [form, setForm] = useState([]);
    // const [popupStatus, setPopupStatus] = useState(false);
    // const [popupIndex, setPopupIndex] = useState(0);
    const [checkboxMessage, setCheckboxMessage] = useState("");
    const [termsStatus, setTermsStatus] = useState(false);
    // const [privacyStatus, setPrivacyStatus] = useState(false);
    const [announcementsStatus, setAnnouncementstatus] = useState(false);

    useEffect(() => {
        setForm(form_lang["form_en"]);
    }, []);

    const handleChange = (value, index, indexForm) => {
        const newForm = [...form];
        newForm[index].form[indexForm].value = value;
    };

    const handleCheckboxChange = (value, index) => {
        if (index === 1) {
            setTermsStatus(value);
        }
        // if(index === 2){
        //     setPrivacyStatus(value);
        // }
        if (index === 3) {
            setAnnouncementstatus(value);
        }
    };

    // const handleCheckboxClick = index => {
    //     setPopupStatus(true);
    // }

    // const handlePopup = () => {
    //     setPopupStatus(false);
    // }

    const handleSubmit = () => {
        const resValidation = validate(form);
        if (resValidation[1]) {
            setForm(resValidation[0]);
            return false;
        }

        if (!termsStatus) {
            setCheckboxMessage("You should accept our Terms and Conditions");
            return false;
        }

        // if(!privacyStatus){
        //     setCheckboxMessage("You should accept our Privacy Policy");
        //     return false;
        // }
        //
        // if(!announcementsStatus){
        //     setCheckboxMessage("You should accept Emails & Announcements");
        //     return false;
        // }
        setCheckboxMessage("");

        executeRecaptcha("submit").then((token) => {
            let inputForm = {};

            form.map((item, index) => {
                item.form.map((itemForm) => {
                    inputForm[itemForm.name] = itemForm.value;
                });
            });

            fetch(process.env.REACT_APP_URL_API + "/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    data: JSON.stringify(inputForm),
                    response: token,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.success !== undefined && data.success === 200) {
                        window.location = "/success";
                    } else {
                        if (data.error !== undefined) {
                            if (data.error === 400) setCheckboxMessage("Fields cannot be empty");
                            if (data.error === 300)
                                setCheckboxMessage("Captcha has expired, refresh the page and try again");
                            if (data.error === 301) setCheckboxMessage("Wrong email format");
                            if (data.error === 302) setCheckboxMessage("Team name already exists");
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setCheckboxMessage("Error while registering, try again or contact us");
                });
        });
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    {form_lang["form_en"].map((item, index) => (
                        <div
                            className={`col-12 ${css.column} ${item.large !== undefined ? css.columnLg : "col-lg-6"} `}
                            key={index}
                        >
                            {item.title !== "" && <div className={css.title}>{item.title}</div>}
                            {item.text !== "" && <div className={css.text}>{item.text}</div>}
                            {item.form.map((itemForm, indexForm) => (
                                <div key={indexForm} className={css.input}>
                                    <Input
                                        type={itemForm.type}
                                        name={itemForm.name}
                                        placeholder={itemForm.placeholder}
                                        list={itemForm.list}
                                        minYears={itemForm.minYears}
                                        maxYears={itemForm.maxYears}
                                        error={itemForm.error}
                                        errorMessage={itemForm.errorMessage}
                                        onChange={(val) => handleChange(val, index, indexForm)}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                    <div className={`col-12 ${css.formFooter}`}>
                        <div className={css.description}>
                            I have read the Ts & Cs and consent to the use of my personal data as per the Privacy
                            Notice. I have the option to opt-out anytime.
                        </div>
                        <BasicCheckbox
                            label={`I accept <a target='_blank' rel='noopener noreferrer' href="${tcPdf}">Terms & conditions</a> AND <a target='_blank' rel='noopener noreferrer' href="${policyPdf}">Privacy policy</a>.`}
                            onChange={(e) => handleCheckboxChange(e, 1)}
                        />
                    </div>
                    <div className="col-12">
                        <div className="errorMessage">{checkboxMessage}</div>
                    </div>

                    <div className={`col-12 ${css.formFooter}`}>
                        <div className={css.description}>
                            I consent to receiving product information and promotional offers from PepsiCo,
                            electronically, including, SMS and WhatsApp.
                        </div>
                        <BasicCheckbox
                            label="I accept to receive emails & announcements"
                            onChange={(e) => handleCheckboxChange(e, 3)}
                        />
                    </div>

                    <div className="col-12">
                        <div className={css.button} onClick={handleSubmit}>
                            {en.submit}
                        </div>
                    </div>
                </div>
            </div>

            {/*<Popup*/}
            {/*    // title={terms_en.title}*/}
            {/*    // html={terms_en.html}*/}
            {/*    html={<Tc/>}*/}
            {/*    status={popupStatus}*/}
            {/*    index={popupIndex}*/}
            {/*    onClick={handlePopup}*/}
            {/*/>*/}
        </>
    );
};

export default Form;
