import React from "react";
import { Link } from "react-router-dom";
import css from "./Header.module.css";
import logo from "../../images/logo.png";

const Header = () => {
    return (
        <div className={css.container}>
            <Link to="/">
                <img alt="" src={logo} />
            </Link>
        </div>
    );
};

export default Header;
